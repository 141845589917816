// jshint ignore:start
import React from 'react';
import ajax from 'misago/services/ajax';
import snackbar from 'misago/services/snackbar';
import store from 'misago/services/store';
import { hydrate, append } from 'misago/reducers/users'; // jshint ignore:line
import AttachmentsList from 'misago/components/attachments-list'; // jshint ignore:line
import title from 'misago/services/page-title';


export default class extends React.Component {
  constructor(props) {
    super(props);

    this.setSpecialProps();

    if (misago.has(this.PRELOADED_DATA_KEY)) {
      this.initWithPreloadedData(misago.pop(this.PRELOADED_DATA_KEY));
    } else {
      this.initWithoutPreloadedData();
    }
  }

  setSpecialProps() {
    this.PRELOADED_DATA_KEY = 'PROFILE_ATTACHMENTS';
    this.TITLE = gettext('Upload');
    this.API_FILTER = 'attachments';
  }

  initWithPreloadedData(data) {
    this.state = {
      isLoaded: true,
      isBusy: false,

      search: '',

      count: data.count,
      more: data.more,

      page: data.page,
      pages: data.pages
    };

    store.dispatch(hydrate(data.results));
  }

  initWithoutPreloadedData() {
    this.state = {
      isLoaded: false,
      isBusy: false,

      search: '',

      count: 0,
      more: 0,

      page: 1,
      pages: 1
    };

    this.loadAttachments();
  }

  loadAttachments(page=1) {
    const apiUrl = this.props.profile.api[this.API_FILTER];
    ajax.get(apiUrl, {
      search: '',
      page: page || 1
    }, 'user-' + this.API_FILTER).then((data) =>{
      if (page === 1) {
        store.dispatch(hydrate(data.results));
      } else {
        store.dispatch(append(data.results));
      }

      this.setState({
        isLoaded: true,
        isBusy: false,

        count: data.count,
        more: data.more,

        page: data.page,
        pages: data.pages
      });
    }, (rejection) => {
      snackbar.apiError(rejection);
    });
  }

  componentDidMount() {
    title.set({
      title: this.TITLE,
      parent: this.props.profile.username
    });
  }

  loadMore = () => {
    this.setState({
      isBusy: true
    });

    this.loadAttachments(this.state.page + 1);
  };

  getMoreButton() {
    if (!this.state.more) return null;

    /* jshint ignore:start */
    return (
      <div className="pager-more">
        <Button
          className="btn btn-default btn-outline"
          loading={this.state.isBusy}
          onClick={this.loadMore}
        >
          {interpolate(gettext("Show more (%(more)s)"), {
            'more': this.state.more
          }, true)}
        </Button>
      </div>
    );
    /* jshint ignore:end */
  }

  getListBody() {
    /* jshint ignore:start */
    if (this.state.isLoaded && this.state.count === 0) {
      /* jshint ignore:start */
      return null;
      /* jshint ignore:end */
    }


    return (
      <div>
        <AttachmentsList
          isReady={this.state.isLoaded}
          threads={this.props.posts.results}
        />
        {this.getMoreButton()}
      </div>
    );
    /* jshint ignore:end */
  }

  render() {

    return (
      <div>
        {this.getListBody()}
        {this.getMoreButton()}
      </div>
    )
  }
}
