import React from 'react';

export default function(props) {
  var post = props.post;
  if (post.attachments == null){
    return null;
  }
  return (
    <img
      src={post.attachments[0].url.thumb}
    />
  );
}
