import React from 'react';

export default class extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <a href={this.props.post.url.index}>
          <img src={this.props.attachment.url.thumb}></img>
        </a>
      </div>
    );
    
  }

}