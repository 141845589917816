import React from 'react';
import Button from 'misago/components/button'; // jshint ignore:line
import Form from 'misago/components/form';
import FormGroup from 'misago/components/form-group'; // jshint ignore:line
import PasswordStrength from 'misago/components/password-strength'; // jshint ignore:line
import misago from 'misago';
import ajax from 'misago/services/ajax';
import auth from 'misago/services/auth'; // jshint ignore:line
import captcha from 'misago/services/captcha';
import modal from 'misago/services/modal';
import snackbar from 'misago/services/snackbar';
import showBannedPage from 'misago/utils/banned-page';
import * as validators from 'misago/utils/validators';

export class RegisterForm extends Form {
  constructor(props) {
    super(props);

    const { username, password } = this.props.criteria;

    let passwordMinLength = 0;
    password.forEach((item) => {
      if (item.name === 'MinimumLengthValidator') {
        passwordMinLength = item.min_length;
      }
    });

    this.state = {
      isLoading: false,

      username: '',
      yearOfBirth: '',
      email: '',
      password: '',
      captcha: '',

      validators: {
        username: [
          validators.usernameContent(),
          validators.usernameMinLength(username.min_length),
          validators.usernameMaxLength(username.max_length)
        ],
        email: [
          validators.email()
        ],
        password: [
          validators.passwordMinLength(passwordMinLength)
        ],
        yearOfBirth: [
          validators.required()
        ],
        captcha: captcha.validator()
      },

      errors: {}
    };
  }

  clean() {
    if (this.isValid()) {
      return true;
    } else {
      snackbar.error(gettext("Form contains errors."));
      this.setState({
        errors: this.validate()
      });
      return false;
    }
  }

  send() {
    return ajax.post(misago.get('USERS_API'), {
      username: this.state.username,
      year_of_birth: this.state.yearOfBirth,
      email: this.state.email,
      password: this.state.password,
      captcha: this.state.captcha
    });
  }

  handleSuccess(apiResponse) {
    this.props.callback(apiResponse);
  }

  handleError(rejection) {
    if (rejection.status === 400) {
      this.setState({
        'errors': Object.assign({}, this.state.errors, rejection)
      });

      if (rejection.__all__ && rejection.__all__.length > 0) {
        snackbar.error(rejection.__all__[0]);
      } else {
        snackbar.error(gettext("Form contains errors."));
      }
    } else if (rejection.status === 403 && rejection.ban) {
      showBannedPage(rejection.ban);
      modal.hide();
    } else {
      snackbar.apiError(rejection);
    }
  }

  getLegalFootNote() {
    if (!misago.get('TERMS_OF_SERVICE_URL')) return null;

    /* jshint ignore:start */
    return (
      <p className="legal-footnote">
        <span className="material-icon">
          info_outline
        </span>
        <a
          href={misago.get('TERMS_OF_SERVICE_URL')}
          target="_blank"
        >
          {gettext("By registering you agree to site's terms and conditions.")}
        </a>
      </p>
    );
    /* jshint ignore:end */
  }

  render() {
    /* jshint ignore:start */
    return <div className="modal-dialog modal-register" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal"
                  aria-label={gettext("Close")}>
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="modal-title">{gettext("Register")}</h4>
        </div>
        <form onSubmit={this.handleSubmit}>
          <input type="type" style={{display: 'none'}} />
          <input type="password" style={{display: 'none'}} />
          <div className="modal-body">

            <FormGroup label={gettext("Username (no email)")} for="id_username"
                       validation={this.state.errors.username}>
              <input type="text" id="id_username" className="form-control"
                     aria-describedby="id_username_status"
                     disabled={this.state.isLoading}
                     onChange={this.bindInput('username')}
                     value={this.state.username} />
            </FormGroup>

            <FormGroup label={gettext("Year of birth")} for="id_yearOfBirth"
                       validation={this.state.errors.yearOfBirth}>
              <select id="id_yearOfBirth" className="form-control"
                     aria-describedby="id_yearOfBirth_status"
                     disabled={this.state.isLoading}
                     onChange={this.bindInput('yearOfBirth')}
                     value={this.state.yearOfBirth} >
                     <option key="" value="">---</option>
                     <option key="2012" value="2012">2012</option>
                     <option key="2011" value="2011">2011</option>
                     <option key="2010" value="2010">2010</option>
                     <option key="2009" value="2009">2009</option>
                     <option key="2008" value="2008">2008</option>
                     <option key="2007" value="2007">2007</option>
                     <option key="2006" value="2006">2006</option>
                     <option key="2005" value="2005">2005</option>
                     <option key="2004" value="2004">2004</option>
                     <option key="2003" value="2003">2003</option>
                     <option key="2002" value="2002">2002</option>
                     <option key="2001" value="2001">2001</option>
                     <option key="2000" value="2000">2000</option>
                     <option key="1999" value="1999">1999</option>
                     <option key="1998" value="1998">1998</option>
                     <option key="1997" value="1997">1997</option>
                     <option key="1996" value="1996">1996</option>
                     <option key="1995" value="1995">1995</option>
                     <option key="1994" value="1994">1994</option>
                     <option key="1993" value="1993">1993</option>
                     <option key="1992" value="1992">1992</option>
                     <option key="1991" value="1991">1991</option>
                     <option key="1990" value="1990">1990</option>
                     <option key="1989" value="1989">1989</option>
                     <option key="1988" value="1988">1988</option>
                     <option key="1987" value="1987">1987</option>
                     <option key="1986" value="1986">1986</option>
                     <option key="1985" value="1985">1985</option>
                     <option key="1984" value="1984">1984</option>
                     <option key="1983" value="1983">1983</option>
                     <option key="1982" value="1982">1982</option>
                     <option key="1981" value="1981">1981</option>
                     <option key="1980" value="1980">1980</option>
                     <option key="1979" value="1979">1979</option>
                     <option key="1978" value="1978">1978</option>
                     <option key="1977" value="1977">1977</option>
                     <option key="1976" value="1976">1976</option>
                     <option key="1975" value="1975">1975</option>
                     <option key="1974" value="1974">1974</option>
                     <option key="1973" value="1973">1973</option>
                     <option key="1972" value="1972">1972</option>
                     <option key="1971" value="1971">1971</option>
                     <option key="1970" value="1970">1970</option>
                     <option key="1969" value="1969">1969</option>
                     <option key="1968" value="1968">1968</option>
                     <option key="1967" value="1967">1967</option>
                     <option key="1966" value="1966">1966</option>
                     <option key="1965" value="1965">1965</option>
                     <option key="1964" value="1964">1964</option>
                     <option key="1963" value="1963">1963</option>
                     <option key="1962" value="1962">1962</option>
                     <option key="1961" value="1961">1961</option>
                     <option key="1960" value="1960">1960</option>
                     <option key="1959" value="1959">1959</option>
                     <option key="1958" value="1958">1958</option>
                     <option key="1957" value="1957">1957</option>
                     <option key="1956" value="1956">1956</option>
                     <option key="1955" value="1955">1955</option>
                     <option key="1954" value="1954">1954</option>
                     <option key="1953" value="1953">1953</option>
                     <option key="1952" value="1952">1952</option>
                     <option key="1951" value="1951">1951</option>
                     <option key="1950" value="1950">1950</option>
              </select>
            </FormGroup>

            <FormGroup label={gettext("Your e-mail or your parents' e-mail")} for="id_email"
                       validation={this.state.errors.email}>
              <input type="text" id="id_email" className="form-control"
                     aria-describedby="id_email_status"
                     disabled={this.state.isLoading}
                     onChange={this.bindInput('email')}
                     value={this.state.email} />
            </FormGroup>

            <FormGroup label={gettext("Password")} for="id_password"
                       validation={this.state.errors.password}
                       extra={
                          <PasswordStrength
                            password={this.state.password}
                            inputs={[
                              this.state.username,
                              this.state.email
                            ]}
                          />
                        } >
              <input type="password" id="id_password" className="form-control"
                     aria-describedby="id_password_status"
                     disabled={this.state.isLoading}
                     onChange={this.bindInput('password')}
                     value={this.state.password} />
            </FormGroup>

            {captcha.component({
              form: this,
            })}

            {this.getLegalFootNote()}

          </div>
          <div className="modal-footer">
            <button
              className="btn btn-default"
              data-dismiss="modal"
              disabled={this.state.isLoading}
              type="button"
            >
              {gettext("Cancel")}
            </button>
            <Button className="btn-primary" loading={this.state.isLoading}>
              {gettext("Register account")}
            </Button>
          </div>
        </form>
      </div>
    </div>;
    /* jshint ignore:end */
  }
}

export class RegisterComplete extends React.Component {
  getLead() {
    if (this.props.activation === 'user') {
      return gettext("%(username)s, your account has been created but you need to activate it before you will be able to sign in.");
    } else if (this.props.activation === 'admin') {
      return gettext("%(username)s, your account has been created but board administrator will have to activate it before you will be able to sign in.");
    }
  }

  getSubscript() {
    if (this.props.activation === 'user') {
      return gettext("We have sent an e-mail to %(email)s with link that you have to click to activate your account.");
    } else if (this.props.activation === 'admin') {
      return gettext("We will send an e-mail to %(email)s when this takes place.");
    }
  }

  render() {
    /* jshint ignore:start */
    return <div className="modal-dialog modal-message modal-register"
                role="document">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal"
                  aria-label={gettext("Close")}>
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="modal-title">{gettext("Registration complete")}</h4>
        </div>
        <div className="modal-body">
          <div className="message-icon">
            <span className="material-icon">
              info_outline
            </span>
          </div>
          <div className="message-body">
            <p className="lead">
              {interpolate(
                this.getLead(),
                {'username': this.props.username}, true)}
            </p>
            <p>
              {interpolate(
                this.getSubscript(),
                {'email': this.props.email}, true)}
            </p>
            <button
              className="btn btn-default"
              data-dismiss="modal"
              type="button"
            >
              {gettext("Ok")}
            </button>
          </div>
        </div>
      </div>
    </div>;
    /* jshint ignore:end */
  }
}

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      complete: false
    };
  }

  /* jshint ignore:start */
  completeRegistration = (apiResponse) => {
    if (apiResponse.activation === 'active') {
      modal.hide();
      auth.signIn(apiResponse);
    } else {
      this.setState({
        complete: apiResponse
      });
    }
  };
  /* jshint ignore:end */

  render() {
    /* jshint ignore:start */
    if (this.state.complete) {
      return (
        <RegisterComplete
          activation={this.state.complete.activation}
          email={this.state.complete.email}
          username={this.state.complete.username}
        />
      );
    }

    return (
      <RegisterForm
        callback={this.completeRegistration}
        {...this.props}
      />
    );
    /* jshint ignore:end */
  }
}
