/* jshint ignore:start */
import React from 'react';
import { Pager, More } from './paginator';
import PostsModeration from './moderation/posts';
import ReplyButton from './reply-button';
import SubscriptionSwitch from './subscription';

export default function(props) {
  return null;
}

export function Options(props) {
  if (!props.visible) return null;

  return (
    <div className="col-md-5">
      {props.children}
    </div>
  )
}

export function Moderation(props) {
  if (!props.user.id) return null;

  return (
    <div className="col-sm-4 hidden-xs">
      <PostsModeration {...props} />
    </div>
  )
}


export function Subscription(props) {
  let xsClass = "col-xs-6";
  if (!props.thread.acl.can_reply) {
    xsClass = 'col-xs-12';
  }

  return (
    <div className={xsClass + " col-sm-4"}>
      <SubscriptionSwitch
        btnClassName="btn-block"
        className="dropup"
        {...props}
      />
    </div>
  );
}

export function Reply(props) {
  if (!props.thread.acl.can_reply) return null;

  return (
    <div className="col-xs-6 col-sm-4">
      <ReplyButton
        className="btn btn-primary btn-block btn-outline"
        onClick={props.onClick}
      />
    </div>
  );
}

export function Spacer(props) {
  if (props.thread.acl.can_reply) return null;

  return (
    <div className="hidden-xs hidden-sm col-sm-4"></div>
  );
}
