import React from 'react';
import Attachment from './attachment';

class ThreadAttachment extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.post.attachments == null || this.props.post.attachments.filter(a => a.filetype == "Blendfile").length <= 0 ){
      return null;
    }

    return (
      <div>
        <h4>{this.props.post.thread.title}</h4>
        { this.props.post.attachments.filter(a => a.filetype == "Blendfile").map((attachment) => {
          return (
            <Attachment
              post = {this.props.post}
              attachment = {attachment}
            />
          );
      })}
      </div>
    );
  }

}
export default class extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.isReady) {
      return null;
    }

    return (
      <div>
      { this.props.threads.map((thread) => {
        return (<ThreadAttachment
            post = {thread}
          />)
      })}
      </div>
    );
  }
}
