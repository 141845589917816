import React from 'react';
import Avatar from 'misago/components/avatar'; // jshint ignore:line
import NavbarSearch from 'misago/components/navbar-search'; // jshint ignore:line
import RegisterButton from 'misago/components/register-button'; // jshint ignore:line
import SignInModal from 'misago/components/sign-in.js';
import dropdown from 'misago/services/mobile-navbar-dropdown';
import modal from 'misago/services/modal';
import misago from 'misago/index';
import GoogleLogin from 'react-google-login';
import ajax from 'misago/services/ajax';

const responseGoogle = (response) => {
  console.log(response);
  return ajax.post(misago.get('AUTH_API'), {
    'username': response.profileObj.givenName,
    'email': response.profileObj.email,
    'google_id': response.profileObj.googleId,
  })
  .then(function(data) {
    document.location.reload();
  });
};

export class GuestMenu extends React.Component {
  showSignInModal() {
    modal.show(SignInModal);
  }

  render() {
    /* jshint ignore:start */
    return (
      <ul
        className="dropdown-menu user-dropdown dropdown-menu-right"
        role="menu"
      >
        <li className="guest-preview">
          <h4>{gettext("You are browsing as guest.")}</h4>
          <p>
            {gettext('Sign in or register to start and participate in discussions.')}
          </p>
          <div className="row">
            <div className="col-xs-6">

              <button
                className="btn btn-default btn-block"
                onClick={this.showSignInModal}
                type="button"
              >
                {gettext("Sign in")}
              </button>

            </div>
            <div className="col-xs-6">

              <RegisterButton className="btn-primary btn-block">
                {gettext("Register")}
              </RegisterButton>

            </div>
          </div>
        </li>
      </ul>
    );
    /* jshint ignore:end */
  }
}

export class GuestNav extends GuestMenu {
  render() {
    /* jshint ignore:start */
    /* NOTE: [b3d] removed NavbarSearch */
    return (
      <div className="nav nav-guest">
        <div className="navbar-left">
        </div>
        <button
          className="btn navbar-btn btn-default"
          onClick={this.showSignInModal}
          type="button"
        >
          {gettext("Sign in")}
        </button>

        <GoogleLogin
          clientId="203646451412-qscdrp0qc1dt2vb6046fqkk2d0mpaeuq.apps.googleusercontent.com"
          render={renderProps => (
            <button onClick={renderProps.onClick}>Sign in with Google</button>
          )}
          className="btn navbar-btn btn-default"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
        />
        <RegisterButton className="navbar-btn btn-primary btn-outline">
          {gettext("Register")}
        </RegisterButton>
      </div>
    );
    /* jshint ignore:end */
  }
}

export class CompactGuestNav extends React.Component {
  showGuestMenu() {
    dropdown.show(GuestMenu);
  }

  render() {
    /* jshint ignore:start */
    return (
      <button type="button" onClick={this.showGuestMenu}>
        <Avatar size="64" />
      </button>
    );
    /* jshint ignore:end */
  }
}
