import React from 'react';
import misago from 'misago';

export default function(props) {
  var thread = props.thread;
  if (thread.attachments == null){
    return (<a href={thread.url.index} className="item-title thread-title">
      {thread.title}
    </a>);
  }
  return (
    <div>
    <a href={thread.url.index} className="item-title thread-title">
      {thread.title}
    </a><br/>
    <img
      src={thread.attachments[0].url.thumb}
    />
    </div>
  );
}
