
function onbodyload() {
  var otherwork_placeholder = $("#otherwork-grid-small");
  if (otherwork_placeholder.length != 0) {
    $.getJSON(
      "/api/otherwork/",
      function(data) {
        var html = "";
        data.forEach(
          function(thread) {
            thread.attachments.forEach(function(attachment) {
              html += "<div>";
              html += "<a href='"+thread.url+"' title='"+thread.title+"'>";
              html += "<img src='"+attachment.url.thumb+"' alt='"+thread.title+"'>";
              html += "</img>";
              html += "</a>";
              html += "</div>";
            });
          }
        );
        otherwork_placeholder.html(html);

      }
    );
  }
}
$(document).ready(
  onbodyload
);
